@import "assets/style/breakpoints/breakpoints.scss";
.send-receive-payments {
  .image-cont {
    display: flex;
    justify-content: left;
    align-items: left;
    width: 48%;
    height: 100%;
    position: relative;
    @include large {
      width: 100%;
      margin-bottom: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      object-position: center;
      width: 90%;
      object-fit: contain;
      display: block;
      transition: box-shadow 0.3s ease-in-out;
      box-shadow: 0px 27px 40px rgba(60, 59, 78, 0.158);
      &:hover {
        box-shadow: none;
      }
      @include large {
        width: 60%;
      }
      @include medium {
        width: 90%;
      }
      @include small {
        width: 100%;
      }
    }
    .img-1-cont {
      position: absolute;
      right: -8%;
      top: 10%;
      @include large {
        right: -3%;
      }
      @include medium {
        right: -7%;
      }
      @include small {
        right: -16%;
        top: 0%;
      }
      img {
        min-width: 14rem;
        border-radius: 15px;
        @include medium {
          transform: scale(0.8);
        }
        @include small {
          transform: scale(0.6);
        }
      }
    }
    .img-2-cont {
      position: absolute;
      right: -15%;
      bottom: 5%;
      border-radius: 16px;
      @include large {
        right: -3%;
      }
      @include medium {
        right: -8%;
      }
      @include small {
        right: -21%;
      }
      img {
        min-width: 18rem;
        border-radius: 16px;
        @include medium {
          transform: scale(0.8);
        }
        @include small {
          transform: scale(0.6);
        }
      }
    }
    .img-3-cont {
      position: absolute;
      top: 63%;
      left: -10%;
      border-radius: 16px;
      @include large {
        left: -3%;
      }
      @include medium {
        left: -8%;
      }
      @include small {
        left: -21%;
        top: 50%;
      }
      img {
        min-width: 18rem;
        border-radius: 15px;
        @include medium {
          transform: scale(0.8);
        }
        @include small {
          transform: scale(0.6);
        }
      }
    }
  }
}
