.button-cont {
  position: relative;
  top: -0.8rem;
}
.email {
  border: 1px solid var(--disabled);
  padding: 0.5rem;
  width: 70%;
  margin-top: 3rem;
  border-radius: 7px;
}
