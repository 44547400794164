@import "assets/style/breakpoints/breakpoints.scss";
.primary {
  font-size: 16px;
  font-family: montserrat-bold;
  border-radius: 7px;
  background: linear-gradient(317.83deg, #01e285 -13.04%, #09cead 127.04%);
  box-shadow: 0px 27px 40px rgba(60, 59, 78, 0.158);
  transition: box-shadow 0.2s ease-in-out;
  width: 143px;
  height: 52px;
  @include medium {
    height: 42px;
    font-size: 14px;
    width: 120px;
  }
  &:hover {
    box-shadow: none;
    transform: scale(0.97);
  }
}
