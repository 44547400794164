@import "assets/style/breakpoints/breakpoints.scss";
.choose-cont {
  border-radius: 20px;
  position: relative;
  margin: 10rem 0 2rem;
  padding: 5rem;
  @include large {
    padding: 60px 22px;
  }
  @include small {
    padding: 60px 7px 30px;
    margin: 5rem 0 2rem;
  }
  h2 {
    font-size: 46px;
    background-color: white;
    font-family: montserrat-extra-bold;
    text-align: center;
    @include large {
      font-size: 22px;
    }
  }
  .lines-cont {
    position: absolute;
    background-color: transparent;
    top: -2rem;
    @include large {
      top: -3rem;
    }
    svg {
      background-color: transparent;
      @include large {
        max-width: 4rem;
      }
    }
  }
}
