$xs: 375px;
$sm: 425px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;

@mixin extra-small {
  @media only screen and (max-width: $xs) {
    @content;
  }
}
@mixin small {
  @media only screen and (max-width: $sm) {
    @content;
  }
}
@mixin medium {
  @media only screen and (max-width: $md) {
    @content;
  }
}
@mixin large {
  @media only screen and (max-width: $lg) {
    @content;
  }
}
@mixin extra-large {
  @media only screen and (max-width: $xl) {
    @content;
  }
}
