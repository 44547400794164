@import "assets/style/breakpoints/breakpoints.scss";
.benefits-cont {
  .benefits-item {
    text-align: center;
    width: 12%;
    @include large {
      width: 30%;
      padding-top: 2rem;
    }
    .benefits-item-img {
      border-radius: 10px;
      width: fit-content;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 61px;
      height: 60px;
      box-shadow: 0px 10px 40px rgba(60, 59, 78, 0.158);
      transition: box-shadow 0.3s ease;
      &:hover {
        box-shadow: none;
      }
    }
    span {
      font-size: 20px;
      margin-top: 1.5rem;
      display: block;
      font-family: montserrat-bold;
      max-width: 12rem;
      line-height: 30px;
      @include medium {
        font-size: 10px;
        line-height: 15px;
      }
    }
  }
}
