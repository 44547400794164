@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./breakpoints/breakpoints.scss";

@font-face {
  font-family: montserrat;
  src: url("./fonts/montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: montserrat-bold;
  src: url("./fonts/montserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: montserrat-extra-bold;
  src: url("./fonts/montserrat/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: montserrat-light;
  src: url("./fonts/montserrat/Montserrat-Light.ttf");
}

@mixin vhAlignCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin flexCenter($direction) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}
@mixin gridDisplay($columns) {
  display: grid;
  grid-template-columns: $columns;
}
@font-face {
  font-family: manrope;
  src: url("./fonts/manrope/Manrope-Regular.ttf");
}
@font-face {
  font-family: manrope-semibold;
  src: url("./fonts/manrope/Manrope-SemiBold.ttf");
}
@font-face {
  font-family: manrope-bold;
  src: url("./fonts/manrope/Manrope-Bold.ttf");
}
@font-face {
  font-family: manrope-ExtraBold;
  src: url("./fonts/manrope/Manrope-ExtraBold.ttf");
}
@font-face {
  font-family: manrope-light;
  src: url("./fonts/manrope/Manrope-Light.ttf");
}
@layer components {
  .flex-between {
    @apply flex items-center justify-between;
  }
}

:root {
  --main: #04d897;
  --gradient1: #01e285;
  --gradient2: #09cead;
  --background: #f6fbfa;
  --text: #231d56;
  --disabled: #bfc1cf;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: montserrat;
  color: var(--text);
}
html {
  scroll-behavior: smooth;
  body {
    background-color: var(--background);
    ul {
      list-style-type: none;
    }
    a {
      text-decoration: none;
    }
    .ant-modal {
      width: 40%;
      @include medium {
        margin: unset;
        width: 100% !important;
      }
      .ant-modal-content {
        border-radius: 0.5rem;
        padding: 1rem;
        text-align: center;
        @include medium {
          width: 95%;
          margin: auto;
          top: 0;
          position: relative;
          right: -0.5rem;
        }
        .ant-modal-centered::before {
          content: unset;
        }
      }
      .ant-modal-header {
        border-radius: 0.5rem;
        border-bottom: none;
      }
      .ant-modal-title {
        font-family: montserrat-bold;
        font-size: 23px;
        padding-top: 40px;
      }
      .ant-modal-footer {
        padding: 1rem;
        text-align: none;
        border-top: none;
        display: flex;
        justify-content: center;
      }
      .ant-btn.ant-btn-default {
        display: none;
      }
      .ant-btn.ant-btn-primary {
        font-size: 16px;
        font-family: montserrat-bold;
        border-radius: 7px;
        background: linear-gradient(
          317.83deg,
          #01e285 -13.04%,
          #09cead 127.04%
        );
        transition: box-shadow 0.2s ease-in-out;
        width: 143px;
        height: 52px;
        font-weight: 900;
        border: none;
        span {
          color: white;
        }
        &:hover {
          box-shadow: none;
          transform: scale(0.97);
        }
      }
      .ant-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
  }
}
