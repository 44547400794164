@import "assets/style/breakpoints/breakpoints.scss";
.master-business {
  .image-cont {
    display: flex;
    justify-content: right;
    align-items: right;
    width: 48%;
    height: 100%;
    position: relative;
    @include large {
      width: 100%;
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      object-position: center;
      width: 80%;
      object-fit: contain;
      display: block;
      transition: box-shadow 0.3s ease-in-out;
      box-shadow: 0px 27px 40px rgba(60, 59, 78, 0.158);
      border-radius: 30px;
      &:hover {
        box-shadow: none;
      }
      @include large {
        width: 60%;
      }
      @include medium {
        width: 90%;
      }
      @include small {
        width: 100%;
      }
    }
    .img-1-cont {
      position: absolute;
      left: -10%;
      top: 10%;
      border-radius: 15px;
      @include large {
        left: 0%;
      }
      @include medium {
        left: -9%;
      }
      @include small {
        left: -25%;
      }
      @include extra-small {
        left: -27%;
      }
      img {
        min-width: 20rem;
        border-radius: 15px;
        @include medium {
          transform: scale(0.8);
        }
        @include small {
          transform: scale(0.5);
        }
      }
    }
    .img-2-cont {
      position: absolute;
      right: -5%;
      top: 50%;
      border-radius: 15px;
      @include large {
        right: 0%;
      }
      @include medium {
        right: -8%;
      }
      @include small {
        right: -22%;
      }
      @include extra-small {
        right: -24%;
      }
      img {
        min-width: 17rem;
        border-radius: 15px;
        @include medium {
          transform: scale(0.8);
        }
        @include small {
          transform: scale(0.5);
        }
      }
    }
    .img-3-cont {
      position: absolute;
      top: 63%;
      left: -10%;
      border-radius: 15px;
      @include large {
        left: 0%;
        top: 60%;
      }
      @include medium {
        left: -8%;
      }
      @include small {
        left: -14%;
      }
      @include extra-small {
        left: -15%;
      }
      img {
        min-width: 16rem;
        border-radius: 15px;
        @include medium {
          transform: scale(0.8);
        }
        @include small {
          transform: scale(0.7);
        }
      }
    }
  }
}
