@import "assets/style/breakpoints/breakpoints.scss";
.layout {
  width: 90%;
  max-width: 90rem;
  margin: auto;
  padding-top: 5rem;
  position: relative;
  @include medium {
    padding-top: 2rem;
  }
  .logo-cont {
    padding-right: 0.5rem;
    border-right: 2px solid var(--main);
    width: 9rem;
    position: relative;
    top: -1.5rem;
    @include medium {
      top: -0.7rem;
      width: 7.5rem;
    }
    svg {
      @include medium {
        width: 7rem;
      }
    }
  }
  .navbar-cont {
    flex: 1;
    @include medium {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5.5rem;
    }
    a {
      font-size: 24px;
      padding-right: 1rem;
      @include large {
        font-size: 20px;
      }
      @include small {
        font-size: 16px;
      }
      &.active {
        li {
          color: var(--main);
          border-bottom: 2px solid var(--main);
        }
      }
      li {
        font-family: montserrat-bold;
      }
      &:hover:not(.active) li {
        color: #135f4847;
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
      }
    }
  }
}
