@import "assets/style/breakpoints/breakpoints.scss";
.choose-item {
  width: 45%;
  @include large {
    width: 47%;
  }
  @include small {
    width: 48.5%;
  }
}
.button-cont {
  background-color: white;
  padding-top: 3rem;
  text-align: center;
  button {
    border-radius: 30px;
  }
}
.abs-cont {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  border-radius: 50%;
  background: linear-gradient(118.48deg, #fbfdfd 3.92%, #eaf8f4 111.97%);
  text-align: center;
  margin-top: 3rem;
  height: 27rem;
  width: 27rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include large {
    height: 17rem;
    width: 17rem;
    margin-top: 1.5rem;
  }
  @include medium {
    height: 11.5rem;
    width: 11.5rem;
  }
  @include small {
    height: 8rem;
    width: 8rem;
  }
  .icon-cont {
    width: 100%;
    margin: auto;
    text-align: center;
    background-color: transparent;
    svg {
      width: 7rem;
      margin: auto;
      @include large {
        width: 5rem;
        height: 7rem;
      }
      @include medium {
        height: 4rem;
        width: 5rem;
      }
      @include small {
        width: 2.5rem;
        margin-top: 0.3rem;
      }
    }
    h4 {
      background-color: transparent;
      font-size: 28px;
      line-height: 35px;
      font-family: manrope-bold;
      max-width: 16rem;
      margin: auto;
      padding-top: 1.5rem;
      @include large {
        padding-top: 0;
        font-size: 17px;
        line-height: 24px;
        max-width: 80%;
      }
      @include medium {
        font-size: 14px;
        line-height: 19px;
        padding-top: 1rem;
      }
      @include small {
        padding-top: 0rem;
        line-height: 14px;

      }
    }
  }
}
