@import "assets/style/breakpoints/breakpoints.scss";
.choseItem-cont {
  width: 100%;
  border-radius: 27px;
  background: linear-gradient(118.48deg, #fbfdfd 3.92%, #eaf8f4 111.97%);
  text-align: center;
  margin-top: 6rem;
  height: 27rem;
  @include large {
    height: 17rem;
    margin-top: 2.5rem;
    @include small {
      height: 14rem;
    margin-top: 0.7rem;
    }
  }
  .icon-cont {
    width: 100%;
    margin: auto;
    text-align: center;
    height: 7rem;
    background-color: transparent;
    padding-top: 2rem;
    @include large {
      padding-top: 1rem;
    }
    @include small {
      padding-top: 0;
    }
    svg {
      width: 7rem;
      margin: auto;
      @include large {
        width: 5rem;
      }
      @include small {
        width: 3.3rem;
      }
    }
    h4 {
      background-color: transparent;
      font-size: 28px;
      line-height: 35px;
      font-family: manrope-bold;
      max-width: 16rem;
      margin: auto;
      padding-top: 6rem;
      @include large {
        padding-top: 2rem;
        font-size: 17px;
        line-height: 24px;
        max-width: 80%;
      }
      @include medium {
        font-size: 14px;
        line-height: 19px;
      }
      @include small {
        padding-top: 0rem;
        
      }
    }
  }
}
