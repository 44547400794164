@import "assets/style/breakpoints/breakpoints.scss";
.content-cont {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include large {
    width: 100%;
  }
  h1 {
    font-size: 46px;
    font-family: montserrat-extra-bold;
    line-height: 3.5rem;
    @include extra-large {
      font-size: 27px;
      line-height: 2rem;
    }
    @include large {
      font-size: 22px;
      line-height: 2rem;
    }
  }
  p {
    padding-top: 2rem;
    font-size: 20px;
    line-height: 30px;
    @include large {
      padding-top: 1rem;
    }
    @include small {
      font-size: 16px;
    }
    strong {
      color: black;
      font-family: montserrat-extra-bold;
    }
  }
  .button-cont {
    @include large {
      padding-top: 3rem;
    }
    button {
      border-radius: 30px;
    }
  }
}
